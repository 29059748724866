import ITTIModel from '../../ittijs/ITTIModel';
export default class extends ITTIModel {
	constructor() {
		super('Sessions');
	}
	async getSelectOptions(params){
		return await this.fetch('getSelectOptions', params);
	}
	checkCreateSession(utility, template){
		return this.fetch('checkCreateSession', {utility, template});
	}
	checkCreateSessionIzr(utility, template){
		return this.fetch('checkCreateSessionIzr', {utility, template});
	}
	checkCreateSessionIzrCorrection(utility, template){
		return this.fetch('checkCreateSessionIzrCorrection', {utility, template});
	}
	checkCreateSessionIzrIzvanredna(utility, template){
		return this.fetch('checkCreateSessionIzrIzvanredna', {utility, template});
	}
	createSession(utility, template, data){
		return this.fetch('createSession', {utility, template}, data);
	}
	createSessionIzr(utility, template, data){
		return this.fetch('createSessionIzr', {utility, template}, data);
	}
	createSessionIzrCorrection(utility, template, data){
		return this.fetch('createSessionIzrCorrection', {utility, template}, data);
	}
	createSessionIzrIzvanredna(utility, template, data){
		return this.fetch('createSessionIzrIzvanredna', {utility, template}, data);
	}
	getTemplatesForNewSession(utility){
		return this.fetch('getTemplatesForNewSession', {utility});
	}
	getLockStatus(utility, session){
		return this.fetch('isLocked', {utility, session});
	}
	lockSession(utility, session){
		return this.fetch('lockSession', {utility, session}, {});
	}
	unlockSession(utility, session){
		return this.fetch('unlockSession', {utility, session}, {});
	}
	getCreateProgress(utility, session){
		return this.fetch('getCreateProgress', {utility, session});
	}
	async utilityExport(utility, session, files){
		const res = this.uploadWithProgress('utilityExport',
			{utility, session},
			{"files":files}
		);
		try {
			return await res.promise;
		}
		catch (err) {
			if (err.response) {
				throw JSON.parse(err.response)
			}
			throw err;
		}
	}
	getConfigSlujeben(utility, session){
		return this.fetch('getConfigSlujeben', {utility, session});
	}
	recalcSlujebenAll(utility, session, data){
		return this.fetch('recalcSlujebenAll', {utility, session}, data);
	}
	recalcConsumption(utility, session){
		return this.fetch('recalcConsumption', {utility, session}, {});
	}
	exportDevCounts(utility, session){
		return this.fetch('exportDevCounts', {utility, session});
	}
	getIzrPDFsStatus(utility, session) {
		return this.fetch('getIzrPDFsStatus', {utility, session});
	}
	makeIzrPDFs(utility, session, force) {
		return this.fetch('makeIzrPDFs', {utility, session, force}, {});
	}
}