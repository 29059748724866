<template>
	<session-select-base
		v-bind="$attrs"
		v-on="$listeners"
		:value="value"
		@input="$emit('input', $event)"
		:items="items"
		:loading="loading"
		item-text="id"
		hide-details="auto"
		:nav="nav"
		:prev-id="prevId"
		:next-id="nextId"
	/>
</template>

<script>
import { itemsForSelect } from "@/ittijs/utils";
import ModelSessions from "./Model";
import SessionSelectBase from "@/views/sessions/SessionSelectBase";

const modelSessions = new ModelSessions();

export default {
	components: {SessionSelectBase},
	props: {
		value: {},
		utility: {},
		station: {}, // to limit sessions by station
		template: {}, // to limit sessions by template
		nav: Boolean, // whether to show prev/next buttons
		prevId: {}, // override ID of prev session
		nextId: {}, // override ID of next session
	},

	data(){
		return {
			items: [],
			loading: false,
			innerValue: null,
		}
	},

	watch: {
		utility(val){
			if (val) {
				this.loadItems();
			}
			else {
				this.items = [];
				this.$emit('input', null);
			}
		},
		station(){
			this.loadItems();
		},
		template(){
			this.loadItems();
		},
	},

	mounted(){
		this.loadItems();
	},

	methods: {
		loadItems(){
			if (!this.utility) return;
			this.loading = true;
			modelSessions.getSelectOptions({
				'utility_code': this.utility,
				'station': this.station,
				'template': this.template,
			})
				.then(res=>{
					this.items = itemsForSelect(res);
					if (!this.items.some(e=>parseInt(e.id)===parseInt(this.value))) {
						this.$emit('input', null);
					}
				})
				.catch(err => {
					console.log(err)
				})
				.finally(() => {
					this.loading = false;
				});
		},
	},

}
</script>