<template>
	<span>
		<v-icon v-if="item.locked == '1'" class="success--text mx-1">mdi-lock-check</v-icon>
		<v-icon v-else class="primary--text mx-1">mdi-lock-open-variant</v-icon>
		{{ item.month.toString().padStart(2, '0') }}.{{ item.year }}
		<span style="white-space: pre-wrap;"> (<date-format :date="item.date_start"/> - <date-format :date="item.date_end"/>)</span>
		<v-chip small v-if="item.izr_sess == '1'" color="blue" text-color="white" class="mx-1">изр.</v-chip>
		<v-chip small v-else-if="item.izr_sess == '2'" color="deep-orange" text-color="white" class="mx-1">кор.</v-chip>
		<v-chip small v-else-if="item.izr_sess == '3'" color="purple" text-color="white" class="mx-1">изв.</v-chip>
	</span>
</template>

<script>
import DateFormat from "@/ittijs/components/DateFormat";
export default {
	components: {DateFormat},
	props: [
		'item',
	],
}
</script>