export default (callback) => ({
	data: ()=>({
		loading: false,
		data: null,
		abortController: null,
	}),
	methods: {
		async start(){
			if (this.loading) return;
			this.loading = true;
			this.data = null;
			try {
				this.data = await callback(this, ac => { this.abortController = ac; });
			}
			finally {
				this.loading = false;
			}
		},
		stop(){
			if (!this.loading) return;
			this.loading = false;
			this.data = null;
			if (this.abortController && !this.abortController.aborted) {
				this.abortController.abort('Aborted');
			}
		},
		reset(){
			if (this.loading) {
				this.stop();
			}
			else {
				this.data = null;
			}
		},
	},
});