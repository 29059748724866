<template>
	<v-select
		v-bind="$attrs"
		v-on="$listeners"
		:value="value"
		@input="$emit('input', $event)"
		:items="items"
		item-text="id"
	>

		<template v-slot:selection="{item}">
			<session-item-description :item="item" style="min-width: 350px;" />
		</template>

		<template v-slot:item="{item}">
			<session-item-description :item="item" style="min-width: 350px;" />
		</template>

		<template v-slot:append-outer v-if="nav && (sessionPrev || sessionNext)">
			<button-set dense class="my-n2">
				<v-btn :disabled="!sessionPrev" @click.stop="$emit('input', sessionPrev)" height="40">
					<v-icon>mdi-chevron-left</v-icon>
				</v-btn>
				<v-btn :disabled="!sessionNext" @click.stop="$emit('input', sessionNext)" height="40">
					<v-icon>mdi-chevron-right</v-icon>
				</v-btn>
			</button-set>
		</template>

	</v-select>
</template>

<script>
import SessionItemDescription from "@/views/sessions/SessionItemDescription";
import ButtonSet from "@/ittijs/components/ButtonSet";
export default {
	components: {
		SessionItemDescription,
		ButtonSet,
	},
	props: {
		value: {},
		session: {}, // not used, only for sync
		items: Array,
		nav: Boolean, // whether to show prev/next buttons
		prevId: {}, // override ID of prev session
		nextId: {}, // override ID of next session
	},
	computed: {
		sessionPrev(){
			if (this.prevId) return this.prevId;
			if (!this.value) return null;
			let last = null;
			for (const e of [...this.items].filter(item => item.id == this.value || item.izr_sess == '0').reverse()) {
				if (e.id == this.value) {
					return last;
				}
				last = e.id;
			}
			return null;
		},
		sessionNext(){
			if (this.nextId) return this.nextId;
			if (!this.value) return null;
			let last = null;
			for (const e of this.items.filter(item => item.id == this.value || item.izr_sess == '0')) {
				if (e.id == this.value) {
					return last;
				}
				last = e.id;
			}
			return null;
		},
		selectedItem(){
			return this.items && this.items.find(e => e.id == this.value);
		},
	},
	watch: {
		selectedItem: {
			immediate: true,
			handler(){
				this.$emit('update:session', this.selectedItem);
			},
		},
	},
}
</script>