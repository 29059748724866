<template>
	<ep-panel :readonly="checked && ok || !checked || loading">
		<ep-header :color="color" :disable-icon-rotate="checked && ok">
			<template #actions v-if="checked && ok">
				<v-icon color="green">
					mdi-check
				</v-icon>
			</template>
			<div v-if="$slots.heading" :class="{'success--text': checked && ok}">
				<slot name="heading" />
			</div>
			<v-spacer/>
			<div class="text-right mr-2">
				<v-btn small v-if="!loading" @click.stop="$emit('start')" width="90">Проверка</v-btn>
				<v-btn small color="error" v-else @click.stop="$emit('stop')" width="90">
					<v-progress-circular indeterminate size="20" width="2" class="mr-2" />
					Стоп
				</v-btn>
			</div>
		</ep-header>
		<ep-content :color="color">
			<slot name="content" />
		</ep-content>
	</ep-panel>
</template>

<script>
import expansionPanelShort from "@/ittijs/ExpansionPanelShort";

export default {
	mixins: [
		expansionPanelShort,
	],
	props: {
		ok: Boolean,
		checked: Boolean,
		loading: Boolean,
	},
	computed: {
		color(){
			if (this.loading || !this.checked) return 'headings2';
			if (this.ok) return 'green lighten-4';
			return 'orange lighten-3';
		},
	},
}
</script>