<template>
	<div>
		<v-data-table
			ref="dataTable"
			:loading="loading"
			:items="items"
			:headers="combinedHeaders"
			dense
			disable-pagination
			hide-default-footer
			multi-sort
		>
			<template #item.link="{item}">
				<imot-details-link
					target-blank
					:utility="utility"
					:session="session"
					:station="item.station_id"
					:imot="item.imot_id"
				/>
			</template>
		</v-data-table>
		<div class="text-right mt-4">
			<export-csv color="primary" :headers="combinedHeaders" :items="exportItems">
				<v-icon left>mdi-export</v-icon>
				CSV
			</export-csv>
		</div>
	</div>
</template>

<script>
import IModelMixin from "@/ittijs/IModelMixin";
import ExportCsv from "@/ittijs/components/export-csv";
import MountedMixin from "@/ittijs/MountedMixin";
import ImotDetailsLink from "@/ittijs/components/ImotDetailsLink.vue";

export default {
	components: {ImotDetailsLink, ExportCsv},
	mixins: [
		IModelMixin,
		MountedMixin,
	],
	props: [
		'utility',
		'session',
		'items',
		'headers',
	],
	data(){
		return {
			loading: false,
		}
	},
	computed: {
		combinedHeaders(){
			return [
				{text: "Станция", value: "station_N"},
				{text: "Имот",    value: "imot_N"},
				{text: "Адрес",   value: "address"},
				...(this.headers || []),
				{text: "", value: "link", width: "1%", csv: false}
			];
		},
		exportItems(){
			if (!this.mounted) return [];
			return this.$refs.dataTable && this.$refs.dataTable.internalCurrentItems || [];
		},
	},
}
</script>