<template>
	<v-btn v-bind="$attrs" v-on="$listeners" @click="click">
		<slot/>
	</v-btn>
</template>
<script>
export default {
	props: {
		items: Array,
		headers: Array,
		filename: String,
	},
	methods: {
		csvRow(data){
			return data
				.map(v=>{
					if (v === null) {
						v = '';
					}
					else if (typeof v !== 'string') {
						v = v.toString();
					}
					if (v.match(/[,"\r\n]/)) {
						return '"' + v.replace('"', '""') + '"';
					}
					else {
						return v;
					}
				})
				.join(',')
				+ "\r\n";
		},
		downloadFile(csv){
			const utf8 = new TextEncoder().encode(csv);
			const blob = new Blob([
				Uint8Array.from([0xEF, 0xBB, 0xBF]), // UTF8 BOM
				utf8
			], { type: 'text/csv;charset=utf-8;' });
			const exportedFilename = this.filename || 'export.csv';
			if (navigator.msSaveBlob) { // IE 10+
				navigator.msSaveBlob(blob, exportedFilename);
			} else {
				const link = document.createElement("a");
				if (link.download !== undefined) { // feature detection
					// Browsers that support HTML5 download attribute
					const url = URL.createObjectURL(blob);
					link.setAttribute("href", url);
					link.setAttribute("download", exportedFilename);
					link.style.visibility = 'hidden';
					document.body.appendChild(link);
					link.click();
					document.body.removeChild(link);
				}
			}
		},
		click(){
			const headers = this.headers.filter(h => {
				if (typeof h['csv'] !== 'undefined') {
					return h['csv'] !== false;
				}
				return true;
			});
			let csv = this.csvRow(headers.map(h => h.text));
			for (const row of this.items) {
				csv += this.csvRow(headers.map(h => {
					// if h.value has dots, looks deep
					const chunks = h.value.split('.');
					let val = row;
					try {
						for (const chunk of chunks) {
							val = val[chunk];
						}
						return val;
					}
					catch (e) {
						// last resort
						return row[ h.value ];
					}
				}));
			}
			this.downloadFile(csv);
		},
	},
}
</script>